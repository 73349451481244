import { Attachment } from '@/types/Attachment';
import { IpAddress } from '@/types/IpAddress';
import { Participant } from '@/types/Participant';
import { User } from '@/types/User';
import { LiveEvent$Status } from '@ravnur/shared/types/Live';
import { PrepopulatedValue } from '@/modules/metadata/types/Metadata';

export const DEFAULT_GENERATE_CAPTIONS_LANGUAGE_CODE = 'en-US';

export enum LiveSource$Type {
  RMS = 'AMS', // INFO: RMP-5926 should be RMS, but it's not in the API
  GENERIC = 'Generic',
}

export enum LiveSource$Encoding {
  PASSTHROUGH_BASIC = 'PassthroughBasic',
  PASSTHROUGH_STANDARD = 'PassthroughStandard',
  STANDARD = 'Standard',
  PREMIUM = 'Premium',
}

export enum LiveSource$Status {
  UNKNOWN = 'Unknown',
  STOPPED = 'Stopped',
  STARTING = 'Starting',
  RUNNING = 'Running',
  POST_PROCESSING = 'PostProcessing',
  DELETING = 'Deleting',
}

export enum LiveSource$IngestFormat {
  RTMP = 'RTMP',
  NONE = 'none',
  // FRAGMENTED = 'Fragmented',
}

export enum LiveChannel$Indexing {
  NONE = 'None',
  AUDIO = 'Audio',
  ALL = 'All',
}

export enum LiveChannel$Privacy {
  PUBLIC = 'Public',
  RESTRICTED = 'Restricted',
  UNLISTED = 'Unlisted',
}

export type LiveProcessing$Settings = {
  publishImmediately: boolean;
  indexing: Nullable<LiveChannel$Indexing>;
  languageId: Nullable<string>;
  notConvertToVOD?: boolean;
};

export type LiveSource = {
  title: string;
  description: string;
  status: LiveSource$Status;
  type: LiveSource$Type;
  region: string;
  encoding: LiveSource$Encoding;
  enableLowLatency: Nullable<boolean>;
  ingestFormat: Nullable<LiveSource$IngestFormat>;
  enableLiveCaption: Nullable<boolean>;
  isDvr: boolean;
} & Entity;

type LiveSource$BaseDetails = {
  primaryIngestUrl: string;
  secondaryIngestUrl: string;

  createdBy: User;
  createdDate: string;
} & LiveSource;

export type LiveSource$GenericDetails = {
  type: LiveSource$Type.GENERIC;
  streamingUrl: string;
} & LiveSource$BaseDetails;

export type LiveSource$RMSDetails = {
  type: LiveSource$Type.RMS;

  amsAccountId: string; // INFO: RMP-5926 should be rmsAccountId, but it's not in the API
  languageCode: Nullable<string>;
  keyFrameInterval: 1 | 2;
  allowAllIP: boolean;
  ipAddresses: IpAddress[];

  primarySecureIngestUrl: string;
  secondarySecureIngestUrl: string;
  streamingKey: string;
} & LiveSource$BaseDetails;

export type LiveSource$Details = LiveSource$GenericDetails | LiveSource$RMSDetails;

export type RMSAccount = {
  name: string;
} & Entity;

export type RMSLanguage = {
  name: string;
} & Entity;

export type LiveChannel = {
  title: string;
  description: string;
  path: string;
  defaultLiveSourceId: Nullable<string>;
  scheduledMeetingsCount: number;
  enableQA: boolean;
  privacy: LiveChannel$Privacy;
  applicationId: string;
  splashPath: Nullable<string>;
  allowEmbedding: boolean;
  isFeatured: boolean;
  brandingHeader: Nullable<string>;
  bannerPath: Nullable<string>;
  logoPath: Nullable<string>;
  playlistId: Nullable<string>;
} & Entity &
  LiveProcessing$Settings;

export type LiveChannel$Details = {
  allowedParticipants: Participant[];
  emailsForQuestions: string[];

  createdBy: User;
  createdDate: string;
  ts?: Nullable<string>;
} & LiveChannel;

export type LiveEvent$TextOverlay = {
  enabled: boolean;
  textSpeed: number;
  backgroundColor: string;
  text: string;
  textColor: string;
  liveStreamId?: string;
};

export type LiveEvent = {
  title: string;
  status: LiveEvent$Status;
  startDateTime: string;
  autoStart: boolean;
  liveSourceId: string;
  duration: number;
  channelIds: string[];
  textOverlay?: LiveEvent$TextOverlay;
} & Entity;

export type LiveEvent$Metadata = {
  fieldId: string;
  fieldName: string;
  value: PrepopulatedValue['value'];
};

export type LiveEvent$Details = {
  description: string;
  metadata: LiveEvent$Metadata[];
  splashUrl: Nullable<string>;
  startDate: string;
  startTime: string;
  timeZoneId: string;
  notConvertToVOD: boolean;

  languageId: Nullable<string>;

  autoStartPreview: boolean;
  previewAutoStartTime: Nullable<number>;
  autoStartLive: boolean;
  liveAutoStartTime: Nullable<number>;

  attachments?: Attachment[];

  createdBy: User;
  createdDate: string;
  ts?: Nullable<string>;
  accessCode?: Nullable<string>;
} & LiveEvent &
  LiveProcessing$Settings;

export function isOtherLiveSourceType(
  source: Partial<LiveSource$Details>
): source is LiveSource$GenericDetails {
  return source.type === LiveSource$Type.GENERIC;
}

export function isRMSLiveSourceType(
  source: Partial<LiveSource$Details>
): source is LiveSource$RMSDetails {
  return source.type === LiveSource$Type.RMS;
}
